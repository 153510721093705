
html {
  overflow-x: hidden;
}
body {
  font-family: $font-family;
  line-height: 1.8;
  font-size: 15px;
  color: #7a7a7a;
  background: #fff;
  overflow-x: hidden;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    right: 0;
    bottom: 0;
    background:rgba($black, .2);
    opacity: 0;
    visibility: hidden;
    transition: .3s all ease;
  }
  &.offcanvas-menu {
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }  
}
a {
  color: $primary;
  transition: .3s all ease;
  &:hover {
    color: $primary;
    text-decoration: none;
  }
}
h1, h2, h3, h4, h5 {
  color: $heading_color;
}
.text-primary {
  color: $primary!important;
}

.text-black {
  color: $black!important;
}
a.text-primary {
  color: $primary!important;
  &:hover {
    color: $primary!important;
  }
}
.bg-primary {
  background-color: $primary!important;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-family: $font-family;
}


.btn {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 4px;
  font-size: 14px;
  &:active, &:focus {
    outline: none;
    box-shadow: none;
  }
  &.btn-primary {
    background: $primary;
    border-color: $primary;
    &:hover {
      border-color: lighten($primary, 4%);
      background: lighten($primary, 4%);
    }
  }
  &.btn-white {
    background-color: $white;
    &:hover {
      background-color: transparent;
      border-color: $white;
      color: $white;
    }
  }
  &.btn-outline-white {
    border: 1px solid $white;
    &:hover {
      background: $white;
      color: $primary!important;
    }
  }

  &.btn-outline-black {
    border: 1px solid rgba($black, .1);
    &:hover {
      background: transparent;
      color: $primary!important;
    }
  }

}

.form-control, .custom-select {
  border: 2px solid darken($light, 5%);
  font-size: 16px;
  height: 45px;
  &:active, &:focus {
    border-color: $primary;
    box-shadow: none;
  }
}

.gutter-v1 {
    margin-right: -20px;
    margin-left: -20px;
    @include media-breakpoint-down(md) {
      margin-left: -15px;
      margin-right: -15px;
    }
  > .col,
  > [class*="col-"] {
    padding-right: 20px;
    padding-left: 20px;
    @include media-breakpoint-down(md) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.spinner-border {
	color: $primary;
}
/*PRELOADING------------ */
#overlayer {
  width:100%;
  height:100%;  
  position:fixed;
  z-index:7100;
  background: $white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loader {
	z-index:7700;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.position-relative {
  position: relative;
}