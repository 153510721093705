
// accordion
.custom-accordion {
	.accordion-item {
		background-color: lighten(#efefef, 4%); 
		margin-bottom: 0px;
		position: relative;
		border-radius: 0px;
		overflow: hidden;
		.btn-link {
			display: block;
			width: 100%;
			padding: 15px 0;
			text-decoration: none;
			text-align: left;
			color: #999;
			border: none;
			padding-left: 40px;
			border-radius: 0;
			position: relative;
			background: $white;
			&:before {
				font-family: 'icomoon';
				content: "\f196";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 15px;
			}
			&[aria-expanded="true"] {
				font-weight: 700;
				color: $primary;
				
				&:before {
					font-family: 'icomoon';
					content: "\f147";
					position: absolute;
					color: $primary;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			
			

		}
		&.active {
			z-index: 2;
			.btn-link {
				color: $primary;
				box-shadow: 0 1px 10px 0 rgba($black, .1);
			}
		}

		.accordion-body {
			padding: 20px 20px 20px 20px;
			color: #888;
		}
		
	}
}


//checkbox
.control {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 15px;
	cursor: pointer;
	font-size: 18px;

	.caption {
		position: relative;
		top: -3px;
		font-size: 16px;
	}
}
.control input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.control__indicator {
	position: absolute;
	top: 2px;
	left: 0;
	height: 20px;
	width: 20px;
	background: #e6e6e6;
	border-radius: 4px;
}
.control--radio .control__indicator {
	border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
	background: #ccc;
}
.control input:checked ~ .control__indicator {
	background: $primary;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
	background: lighten($primary, 5%);
}
.control input:disabled ~ .control__indicator {
	background: #e6e6e6;
	opacity: 0.9;
	pointer-events: none;
}
.control__indicator:after {
	font-family: 'icomoon';
	content: '\e5ca';
	position: absolute;
	display: none;
	font-size: 14px;
	transition: .3s all ease;
}
.control input:checked ~ .control__indicator:after {
	display: block;
	color: #fff;
}
.control--checkbox .control__indicator:after {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.control--checkbox input:disabled ~ .control__indicator:after {
	border-color: #7b7b7b;
}
.control--checkbox input:disabled:checked ~ .control__indicator {
	background-color: $primary;
	opacity: .2;
}


// gallery
.gal-item {
	display: block;
	margin-bottom: 8px;
	img {
		border-radius: 4px;
		transition: .3s all ease;
		opacity: 1;
	}
	&:hover {
		img {
			opacity: .5;
		}
	}
}

// social
.social-icons {
	li {
		display: inline-block;
		a {
			color: rgba($white, .5%);
			display: inline-block;
			width: 40px;
			height: 40px;
			position: relative;
			border-radius: 50%;
			background: #303030;
			span {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			&:hover {
				color: $white;
			}
		}
		&:first-child {
			a {
				padding-left: 0;
			}
		}
	}
	&.light {
		li {
			a {
				color: $black;
				display: inline-block;
				width: 40px;
				height: 40px;
				position: relative;
				border-radius: 50%;
				background: $light;
			}
		}
	}
}

// video
.video-wrap {
	position: relative;
	.play-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50px;
		height: 50px;
		background: rgba($black, .05);
		border-radius: 50%;
		transition: .3s all ease;
		> span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&:hover {
		.play-wrap {
			width: 60px;
			height: 60px;
		}
	}
}

// testimonial 
.owl-single {
	&.dots-absolute {
		.owl-dots {
			bottom: 40px;
			.owl-dot {
				span {
					background: rgba($white, .5);
				}
			}
		}
	}
	.owl-dots {
		text-align: center;
		position: absolute;
		width: 100%;

		.owl-dot {
			display: inline-block;
			margin: 5px;
			span {
				display: inline-block;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: rgba($black, .2);
			}
			&.active {
				span {
					background: $primary;
				}
			}
		}
	}	
}
.testimonial {
	max-width: 500px;
	text-align: center;
	margin-bottom: 30px;
	.name {
		font-size: 18px;
		color: $black;
	}
	.img-wrap {
		img {
			margin: 0 auto;
			width: 70px;
			border-radius: 50%;
		}
	}
}


.list-check {
	li {
		display: block;
		padding-left: 30px;
		position: relative;
		&:before {
			content: "\e5ca";
			font-family: 'icomoon';
			position: absolute;
			top: -.3rem;
			font-size: 20px;
			left: 0;
		}
	}
	&.primary {
		li {
			&:before {
				color: $primary;
			}
		}
	}
}
