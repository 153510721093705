.overlay-svg {
	background-image: url('../images/overlay.svg');
	background-position: center center;
	background-repeat: no-repeat;
	position: absolute;
	height: 100vh;
	min-height: 600px;
	width: 100%;
	top: 0;
	background-size: cover;

}

.owl-logos img {
	margin: auto 0px;
	max-width: 150px !important;
}

.wrap-slider {
	box-shadow: 0 15px 30px -10px rgba($black, .1);
	max-width: 900px;
	padding: 15px;
	margin-top: -150px;
	position: relative;
	background-color: $white;
}

.custom-feature {
	box-shadow: 0 15px 30px -10px rgba($black, .1);
	background: $white;
	text-align: center;
	padding: 30px;
	border-radius: 4px;

	h3 {
		font-size: 18px;
		font-weight: bold;
	}

	p {
		font-size: 15px;
	}

	*:last-child {
		margin-bottom: 0;
	}

	[class^="icon-"] {
		display: block;
		margin-bottom: 30px;
		font-size: 50px;
		color: $primary;
	}
}


.section-stack {
	position: relative;

	img {
		width: 100%;
		display: block;
	}

	.image-stack::after {
		content: ' ';
		display: table;
		clear: both;
	}

	.image-stack__item--top {
		float: left;
		width: 66%;
		margin-right: -100%;
		position: relative;
		z-index: 1; // tells the browser to make this image on top

		img {
			border: 4px solid $white;
			box-shadow: 0 15px 30px -10px rgba($black, .1);
		}
	}

	.image-stack__item--bottom {
		float: right;
		width: 75%;
	}

	@supports (display: grid) {
		.image-stack {
			display: grid;
			position: relative; // imperative for the overlapping to work
			grid-template-columns: repeat(12, 1fr);
		}

		.image-stack__item--bottom {
			grid-column: 4 / -1;
			grid-row: 1;
			width: 100%;
		}

		.image-stack__item--top {
			grid-row: 1;
			grid-column: 1 / span 8;
			padding-top: 20%; // slightly arbitrary, keeps proportion once resized
			width: 100%;
		}
	}
}

.service {
	display: block;
	background-color: rgb(174, 174, 174);

	img {
		margin-bottom: 30px;
		border-radius: 4px;
	}

	h3 {
		font-size: 18px;
	}
}

.activity {
	width: 250px;
	color: #7a7a7a;
	-webkit-line-clamp: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.about-detail {
	width: 250px;

	div {
		color: #7a7a7a;
		-webkit-line-clamp: 3;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

.footer {
	color: $white;
	align-items: center;

	h4 {
		font-weight: bold;
		max-width: 350px;
		font-size: 25px;
		color: $white;
	}

	.bottom-link {
		color: $white  !important;
	}


	.footer-links {
		h5 {
			color: $white  !important;
		}

		ul {
			list-style-position: outside;
			list-style-type: none;
		}
	}

	ul li a::before {
		content: "> ";
	}
}

.initials {
	background-color: $white;
	width: 45px;
	height: 45px;
	border-radius: 50px;
	padding: 20% 0px !important;
	color: $primary  !important;
	text-align: center;
}

.initials::before {
	content: none !important;
}

.password-btn {
	position: absolute;
	height: 45px;
	top: 35%;
	right: 3px;
	// border-width: 1px 1px 1px 0px;
	// border-radius: 0px !important;
	// border-style: solid;
	// border-color: $black;
	vertical-align: center;

	// background-color: $white;
	img {
		width: 25px;
		height: 25px;
	}
}

.text-showmore {
	color: #7a7a7a;
	-webkit-line-clamp: 5;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.title-showmore {
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.site-cover-2 {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;

	&.overlay {
		position: relative;

		&:before {
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			right: 0;
			top: 0;
			background: rgba($black, .4);
		}
	}
}


.pricing {
	background: $white;
	padding: 30px;

	.price {
		>span {
			color: lighten($black, 80%);

			>span {
				font-size: 3rem;
				color: $primary;
			}
		}
	}

	ul {
		li {
			margin-bottom: 20px;
		}
	}
}

.ul-check {
	margin-bottom: 50px;

	li {
		position: relative;
		padding-left: 35px;
		margin-bottom: 15px;
		line-height: 1.5;

		&:before {
			left: 0;
			font-size: 20px;
			top: -.3rem;
			font-family: "icomoon";
			content: "\e5ca";
			position: absolute;
		}
	}

	&.white {
		li {
			&:before {
				color: $black;
			}
		}
	}

	&.success {
		li {
			&:before {
				color: $success;
			}
		}
	}

	&.primary {
		li {
			&:before {
				color: $primary;
			}
		}
	}
}

.site-section {
	padding: 5rem 0;
}

.hero {
	padding: 70px 0;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&.fixed-attachment {
		background-attachment: fixed;
	}

	&.overlay {
		position: relative;

		&:before {
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			right: 0;
			top: 0;
			background: rgba($black, .2);
		}
	}

	&,
	&>.container>.row {
		height: 100vh;
		min-height: 600px;
	}

	h1 {
		font-size: 34px;
		color: $heading_color;

		@include media-breakpoint-down(md) {
			font-size: 30px;
		}
	}


	figure {
		position: relative;
	}

	.quote {
		padding: 50px;
		background: $heading_color;
		color: $white;
		width: 100%;
		bottom: -20px;
		left: -50px;
		position: absolute;
	}
}

.feature {
	&.d-flex {
		.icon-wrap {
			flex: 0 0 40px;
			margin-right: 15px;
		}
	}

	.icon-wrap {
		position: relative;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: rgba($primary, .2);
		z-index: -1;
		// &:before {
		// 	position: absolute;

		// }
		[class^="icon-"] {
			font-size: 16px;
			position: absolute;
			left: 50%;
			top: 50%;
			color: $primary;
			transform: translate(-50%, -50%);
		}

		span {
			font-size: 30px;
			text-align: center;
		}

	}

	h3 {
		font-size: 14px;
		font-weight: 700;
	}

	p {
		color: $heading_light_color;
		font-size: 14px;
	}
}


.site-blocks-cover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	background-position: center center;

	&.overlay {
		position: relative;

		&:before {
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			right: 0;
			top: 0;
			background: rgba($black, .7);
		}
	}

	.player {
		position: absolute;
		bottom: -250px;
		width: 100%;
	}

	&,
	&>.container>.row {
		min-height: 600px;
		height: calc(100vh);
	}

	&.inner-page-cover {

		&,
		&>.container>.row {
			min-height: 400px;
			height: calc(20vh);
		}
	}

	h1 {
		font-size: 3rem;
		font-weight: 400;
		color: $white;
		font-weight: 900;

		@include media-breakpoint-down(md) {
			font-size: 2rem;
		}
	}

	p {
		color: rgba($white, 1);
		font-size: $font-size-base;
		line-height: 1.5;
		font-weight: 300;

	}

	a {
		color: rgba($white, .5);
		border-bottom: dotted 1px $white;

		&:hover {
			color: $white;
		}
	}

	.btn {
		color: $white;

		&:hover {
			border-color: $white;
			background-color: $white;
			color: $primary;
		}
	}

	.desc {
		font-size: 1.4rem;
		font-weight: 300;

	}

	.intro-text {
		font-size: 16px;
		line-height: 1.5;
	}

	.display-1 {
		@include media-breakpoint-down(md) {
			font-size: $font-size-base + 2rem;
		}
	}
}

.site-cover-2 {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-attachment: fixed;

	&.overlay {
		position: relative;

		&:before {
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			right: 0;
			top: 0;
			background: rgba($black, .7);
		}
	}
}

.count-numbers {
	.number {
		font-size: 40px;
		font-weight: bold;
		color: $white;
	}

	span {
		color: rgba($white, .7);
	}
}

.how-it-works {
	margin-bottom: 70px;

	.step {
		padding: 20px 70px 0 0;
		position: relative;

		.icon-wrap {
			position: relative;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background: $primary;

			>span {
				font-family: $font-family;
				font-size: 18px;
				font-weight: bold;
				color: $white;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}

		}

		h3 {
			font-size: 16px;
			font-weight: 700;
		}

		p {
			font-size: 14px;
		}

	}
}


.trusted-by-companies {
	padding: 0rem 0 4rem 0;
	text-align: center;

	h2 {
		margin-bottom: 30px;
		font-size: 1.2rem;
		font-weight: 700;
	}
}

.pro {
	box-shadow: 0 15px 30px -10px rgba($black, .4);
	position: relative;
	z-index: 2;
}

.pricing {
	// background: $white;
	// padding: 30px;
	text-align: center;

	.bg-white & {
		.price {
			>span {
				color: $black;

				>span {
					font-size: 3rem;
					color: $black;
				}
			}
		}

		ul {
			text-align: left;

			li {
				color: $black;

				&:before {
					color: $black  !important;
				}

				margin-bottom: 20px;

			}

			li.remove {
				&:before {
					color: rgba($black, .4);
				}

				text-decoration: line-through;
				color: rgba($black, .4);
			}

		}
	}

	.price {
		>span {

			// color: $black;
			>span {
				font-size: 3rem;
				// color: $black;
			}
		}
	}

	ul {
		text-align: left;

		li {

			// color: $black;
			&:before {
				// color: $black!important;
			}

			margin-bottom: 20px;

		}

		li.remove {
			&:before {
				color: rgba($black, .4);
			}

			text-decoration: line-through;
			color: rgba($black, .4);
		}

	}
}




/*--------- Start Portfolio Area -------------*/
.portfolio-area {
	.primary-btn {
		color: $white;
		background-color: $primary;
		margin-left: auto;
		margin-right: auto;
	}
}

.portfolio-area .filters {
	text-align: center;
}

.portfolio-area .filters ul {
	padding: 0;
}

.portfolio-area .filters ul li {
	list-style: none;
	display: inline-block;
	padding: 18px 15px;
	cursor: pointer;
	position: relative;
	text-transform: uppercase;
	color: $black;
	font-size: 12px;
	font-weight: 500;

	@media(max-width: 736px) {
		padding: 6px 6px;
	}
}

.portfolio-area .filters ul li:after {
	content: "";
	display: block;
	width: calc(0% - 60px);
	position: absolute;
	height: 2px;
	background: #333;
	transition: width 350ms ease-out;
}

.portfolio-area .filters ul li:hover:after {
	width: calc(100% - 60px);
	transition: width 350ms ease-out;
}

.portfolio-area .filters ul li.active {
	color: $primary;
}

.portfolio-area .filters-content {
	margin-top: 50px;
}

.portfolio-area .filters-content .show {
	opacity: 1;
	visibility: visible;
	transition: all 350ms;
}

.portfolio-area .filters-content .hide {
	opacity: 0;
	visibility: hidden;
	transition: all 350ms;
}

.portfolio-area .filters-content .item {
	text-align: center;
	cursor: pointer;
	margin-bottom: 30px;
}

.portfolio-area .filters-content .item img {
	border-radius: 10px;
}

.portfolio-area .filters-content .p-inner {
	padding: 20px 0px;
	text-align: center;
}


.portfolio-area .filters-content .item .p-inner .cat {
	font-size: 13px;
}

.portfolio-area .filters-content .item img {
	width: 100%;
}




.image {
	opacity: 1;
	display: block;
	width: 100%;
	height: 100%;
	transition: .5s ease;
	backface-visibility: hidden;
}

.middle {
	transition: .5s ease;
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
}


.isotope-card:hover .middle {
	opacity: 1;
}

// .text {
// 	color: white;
// 	font-size: 25px;
// 	padding: 16px 32px;
// }

.isotope-card {
	border-radius: 5px;
	overflow: hidden;

	>a {
		overflow: hidden;
		position: relative;
		display: block;

		&:before {
			position: absolute;
			content: "";
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			opacity: 0;
			visibility: hidden;
			height: 100%;
			z-index: 2;
			transition: .3s all ease;
			background: rgba($black, .5);
		}

		img {
			transition: .3s all ease;
			transform: scale(1.0);
		}

		&:hover {
			&:before {
				visibility: visible;
				opacity: 1;
			}

			img {
				transform: scale(1.05);
			}

			.contents {
				z-index: 3;
				opacity: 1;
				visibility: visible;

				h3 {
					color: $white;
				}
			}
		}
	}

	.contents {


		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		transform: translate(-50%, -50%);
		text-align: center;
		transition: .3s all ease;

	}
}

.thumb {
	.overlay-bg {
		opacity: 0;
		border-radius: 5px;
		@include transition();
	}
}


.filters {
	text-align: center;

	ul {
		padding: 0;
		margin: 0 0 30px 0;

		li {
			display: inline-block;
			cursor: pointer;
			padding: 20px;
			transition: .3s all ease;

			&:hover {
				color: $primary;
			}

			&.active {
				color: $primary;
			}
		}
	}
}

.isotope-card {
	margin-bottom: 30px;
	display: block;

	.relative {
		margin-bottom: 20px;
	}

	.contents {
		h3 {
			color: $white;
			font-size: 18px;
		}

		.cat {
			color: darken($white, 30%);
		}
	}
}

.no-nav {
	.owl-nav {
		display: none;
	}
}

.main-slider,
.custom-slider {
	.owl-dots {
		bottom: -50px;
	}

	.owl-nav {

		position: absolute;
		top: 50%;
		width: 100%;
		transform: translateY(-50%);

		@include media-breakpoint-down(sm) {
			display: none;
		}

		.owl-prev,
		.owl-next {
			position: absolute;
			top: 0;
			transition: .3s all ease;
			box-shadow: 0 15px 30px -5px rgba($black, .2);

			span {
				display: block;
				background: $white;
				padding: 15px;
			}

			&:hover {
				box-shadow: 0 7px 15px -5px rgba($black, .2);
			}
		}

		.owl-prev {
			left: -30px;
		}

		.owl-next {
			right: -30px;

			>span {
				position: relative;
				transform: rotate(-180deg);
			}
		}
	}
}



.services-section {
	background: $light;
	padding: 70px 0;
}

.img-wrap-2 {
	margin-top: -120px;
	position: relative;

	.dotted {
		&:before {
			position: absolute;
			content: "";
			background-image: url('../images/dotted.png');
			height: 273px;
			width: 313px;
			z-index: -1;
			bottom: -50px;
			left: -50px;
		}
	}
}



.blog-entries-section {
	padding: 70px 0;
	background: $light;
}

.post-entry {
	padding: 20px;
	border-radius: 4px;
	border-bottom-left-radius: 0;
	background: $white;
	position: relative;
	top: 0;
	transition: .3s all ease;

	.date {
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		color: $heading_light_color;
	}

	h3 {
		font-size: 16px;
		font-weight: 700;

		a {
			color: $heading_color;
		}
	}

	p {
		font-size: 14px;
	}

	*:last-child {
		margin-bottom: 0;
	}

	&:hover,
	&:focus {
		top: -5px;
		box-shadow: 0 15px 30px 0 rgba($black, .05);
	}
}


.service-2 {
	&.no-shadow {
		&.link {
			box-shadow: none !important;
		}
	}

	.service-icon {
		font-size: 24px;
		display: inline-block;
		width: 50px;
		height: 50px;
		border-radius: 4px;
		position: relative;
		color: $primary;
		background-color: $white;

		>svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.service-contents {
		h3 {
			font-size: 16px;
			color: $black;
		}
	}

	&.horizontal {
		.service-icon {
			flex: 0 0 40px;
			margin-right: 30px;
			width: 40px;
			height: 40px;
			font-size: 20px;

		}
	}

	&.link {
		padding: 20px;
		margin-bottom: 10px;
		border-radius: 7px;

		.service-contents {
			color: #939393;

			*:last-child {
				margin-bottom: 0;
			}
		}

		&:hover,
		&.active {
			background: $white;
			box-shadow: 0 4px 10px -4px rgba($black , .05);

			.service-icon {
				background-color: $light;
				color: $black;
			}
		}

		&.active {
			position: relative;
			overflow: hidden;

			.service-icon {
				color: $white;
				background-color: $primary;
			}
		}
	}
}


.section-grey {
	padding-top: 60px;
	padding-bottom: 60px;
	background: #f5f5f5;

	.img-wrap {
		.img {
			@include media-breakpoint-down(md) {
				margin-top: 0;
			}
		}
	}
}

.team {
	h3 {
		font-size: 20px;
	}

	.pic {
		margin-bottom: 30px;

		img {
			border-radius: 4px;
		}
	}

	.position {
		display: block;
		margin-bottom: 20px;
		font-size: 14px;
	}
}

.slider-team-wrap {
	.owl-dots {
		position: absolutre;
		margin-top: 30px;
	}

	.slider-nav {
		a {
			display: inline-block;
			width: 40px;
			height: 40px;
			position: relative;
			background: $primary;
			border-radius: 4px;
			position: relative;
			top: 0;
			transition: .2s all ease;

			span {
				color: $white;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&:hover {
				background: $white;
				top: -2px;

				span {
					color: $primary;
				}
			}
		}
	}
}

.more {
	position: relative;
	padding-right: 30px;
	display: inline-block;

	span {
		position: absolute;
		top: 50%;
		right: 0;
		font-size: 12px;
		transform: translateY(-50%);
		width: 20px;
		height: 20px;
		background: transparent;
		color: $white;
		line-height: 1.6;
		text-align: center;
		transition: .3s all ease;
	}

	&:hover {
		color: $white;

		span {
			background: $primary;
		}
	}

	&.dark {
		color: $black;

		>span {
			color: $black;
		}

		&:hover {
			color: $primary;

			span {
				color: $white;
				background: $primary;
			}
		}
	}
}


.article {
	margin-bottom: 90px;

	@include media-breakpoint-down(md) {
		margin-bottom: 50px;
	}


	.share {
		top: 20px;

		h3 {
			font-family: $font-family;
			font-size: 12px;

			@include media-breakpoint-down(md) {
				display: inline-block;
			}
		}

		.share-article {
			width: 100%;

			li {
				width: 100%;
				display: block;
				margin-bottom: 10px;

				@include media-breakpoint-down(md) {
					display: inline !important;
				}

				a {
					display: block;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					position: relative;
					color: $black;
					border: 1px solid #efefef;

					@include media-breakpoint-down(md) {
						display: inline-block !important;

					}

					span {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
}


.comment-form-wrap {
	clear: both;
}

.comment-list {
	padding: 0;
	margin: 0;

	.children {
		padding: 50px 0 0 40px;
		margin: 0;
		float: left;
		width: 100%;
	}

	li {
		padding: 0;
		margin: 0 0 30px 0;
		float: left;
		width: 100%;
		clear: both;
		list-style: none;

		.vcard {
			width: 80px;
			float: left;

			img {
				width: 50px;
				border-radius: 50%;
			}
		}

		.comment-body {
			float: right;
			width: calc(100% - 80px);

			h3 {
				font-size: 20px;
			}

			.meta {
				text-transform: uppercase;
				font-size: 13px;
				letter-spacing: .1em;
				color: #ccc;
				margin-bottom: 20px;
			}

			.reply {
				padding: 7px 12px;
				background: lighten($black, 80%);
				color: $white;
				text-transform: uppercase;
				border-radius: 30px;
				font-size: 10px;
				font-weight: 900;
				letter-spacing: .1rem;

				&:hover {
					color: $white;
					background: lighten($black, 55%);
				}
			}
		}
	}
}


.categories_tags {
	font-weight: bold;
}

.post-single-navigation {
	a {
		line-height: 1.5;
		// font-weight: bold;
		border: 1px solid #efefef;
		background-color: transparent;
		padding: 30px;
		width: 48% !important;
		border-radius: 4px;

		span {
			margin-bottom: 10px;
			font-size: 11px;
			text-transform: uppercase;
			color: rgba($black, .4);
		}

		padding-top: 2rem;
		padding-bottom: 2rem;
		display: inline-block;
	}

}

.section-latest {
	padding-top: 100px;
	padding-bottom: 100px !important;
}

.floating-block {
	background: $white;
	box-shadow: 0 15px 30px 0 rgba($black, .05);
	width: 100%;
	padding: 20px;
	top: 20px;

	h2 {
		font-size: 16px;
		font-weight: 700;
		margin: 0;
	}

	h3 {
		font-size: 13px;
	}
}


.hero-slant {
	background-color: #ccc;
	position: relative;
	background-size: cover;

	&.overlay {
		position: relative;

		&:before {
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			right: 0;
			top: 0;
			background: rgba($black, .2);
		}
	}

	.container {
		position: relative;
	}

	&,
	&>.container>.row {
		height: 95vh;
	}

	.sign-up-form {
		padding: 10px;
		border-radius: 7px;
		background: $white;
		box-shadow: 0 15px 30px 0 rgba($black, .1);

		.form-control {
			border: none;

			&:active,
			&:focus {
				outline: none;
				box-shadow: none;
			}
		}

		input[type="submit"] {
			box-shadow: 0 15px 30px 0 rgba($black, .2);
			border-radius: 7px;
		}
	}

	.intro {
		position: relative;
		z-index: 2;
		background-color: rgba($color: #000, $alpha: 0.5);
		max-width: 500px;
		padding: 25px 15px;
		border-radius: 8px;
	}

	.hero_img {
		position: absolute;
		right: 0;
		z-index: 9;
		bottom: 100px;

		@include media-breakpoint-down(md) {
			position: relative;
			margin-top: -200px;
			bottom: -100px;
			margin-bottom: 140px;
		}
	}

	.slant {
		position: absolute;
		width: calc(100% + 2px);
		height: 380px;
		bottom: -2px;
		left: -2px;
		z-index: 1;
		background-size: cover;
		background-position: left top;
		background-repeat: no-repeat;
	}
}



.pricing-section {

	padding: 70px 0;

	@include media-breakpoint-down(md) {
		padding: 30px 0;
	}

	.save-percent {
		font-size: 10px;
		background: $primary;
		padding: 4px 10px;
		font-weight: 700;
		border-radius: 30px;
		color: $white;
	}

	.period {
		font-size: 14px;
	}

	.period-toggle {
		width: 100px;
		border-radius: 30px;
		margin-left: 10px;
		margin-right: 10px;
		height: 30px;
		background: #ccc;
		position: relative;
		transition: .3s all ease;

		&:before {
			transition: .3s all ease;
			content: "";
			position: absolute;
			left: 1px;
			height: 28px;
			width: 28px;
			top: 50%;
			transform: translateY(-50%);
			border-radius: 50%;
			background: $white;
			box-shadow: 0 5px 10px 0 rgba($black, .1);
		}

		&.active {
			&:before {
				left: 71px;
			}
		}
	}

	.pricing-item {
		padding: 70px 30px;
		text-align: center;

		h3 {
			font-weight: 700;
			font-size: 24px;
			margin-bottom: 30px;
		}

		.description {
			// border-bottom: 1px solid #efefef;
			margin-bottom: 20px;

			p {
				// color: $text_light_color;
			}
		}

		&.active {
			// padding: 30px;
			background: $white;
			box-shadow: 0 10px 30px -7px rgba($black, .1);
			border-radius: 7px;

			h3 {
				color: $primary;
			}
		}

		ul {
			text-align: left;

			li {
				line-height: 1.4;
				margin-bottom: 8px;
			}
		}

		.price-wrap {
			display: block;
			color: $primary;
			font-weight: 700;
			font-size: 30px;
			line-height: 1.5;

			>div {
				height: 46px;
				overflow: hidden;
			}

			>.price {
				>div {
					transition: .3s all ease;
				}
			}


		}

		.period-wrap {
			font-size: 16px;

			.period {
				height: 27px;
				line-height: 27px;
				overflow: hidden;
				font-size: 16px;

				>div {
					transition: .3s all ease;
				}
			}

		}

		&.yearly {
			.price-wrap {
				>.price {
					>div {
						transform: translateY(-50%);
					}
				}
			}

			.period-wrap {
				>.period {
					>div {
						transform: translateY(-50%);
					}
				}
			}
		}
	}
}

.unit-4 {
	.unit-4-icon {
		position: relative;

		span {
			// line-height: 0;
			font-size: 3rem;
			color: $primary;
		}

		&:before {
			position: absolute;
			content: "";
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: #ccc;
			opacity: .2;
			z-index: -1;
			top: 10px;
			left: -20px;
		}
	}

	h3 {
		font-size: 20px;
		color: $black;
	}
}

.testimonial-section {
	padding: 70px 0;

	@include media-breakpoint-down(md) {
		padding: 30px 0;
	}
}

.testimonial--wrap {
	padding: 40px 40px 70px 40px;
	background: $white;
	border-radius: 7px;
	box-shadow: 0 15px 30px 0 rgba($black, .05);

	.owl-dots {
		bottom: -55px;
	}
}

.testimonial-item {
	.photo {
		img {
			max-width: 80px;
			border-radius: 50%;
		}
	}

	.author {
		line-height: 1.4;

		cite {
			font-weight: 700;
			font-style: normal;
		}

		span {
			// color: $text_light_color;
		}
	}

	blockquote {
		font-size: 20px;
		color: $black;

		&,
		*:last-child {
			margin-bottom: 0;
		}
	}
}


.custom-nav-wrap {
	position: relative;
	margin-top: 20px;

	.custom-owl-prev,
	.custom-owl-next {
		z-index: 2;
		position: absolute;
		font-size: 20px;
		color: $black;
		width: 40px;
		height: 40px;
		display: block;
		border: 1px solid #efefef;

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&:hover {
			border-color: $black;
		}
	}

	.custom-owl-next {
		left: 50px;
		position: relative;
		position: absolute;
		transform: rotate(180deg);
	}
}


.blog_entry {
	transition: .3s all ease;
	position: relative;

	img {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	h3 {
		font-size: 18px;

		a {
			color: $black;
		}
	}

	.date {
		color: lighten($black, 60%);
		display: block;
		font-size: .9rem;
		margin-bottom: 15px;
	}

	.more {
		a {
			position: relative;
			color: $primary;
			padding-bottom: 10px;
		}
	}

	&:hover {
		box-shadow: 0 15px 30px -7px rgba($black, .2);
	}
}

.owl-logos {
	.owl-nav {
		display: none;
	}

	img {
		margin: 0;
	}
}


.features-lg {

	.feature {
		padding: 70px 0;

		@include media-breakpoint-down(md) {
			padding: 30px 0;
		}
	}
}